.btn{@include border-radius(3px);
    text-transform: $uppercaselink;
    font-weight:  $fontmedium;
    @include transition(all .3s ease-in-out);
}

    .btn {
        display: $display-inline-block;
        font-family: $btn-font-family;
        font-weight:  $fontmedium;
        color: $primary-color;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        background-color: transparent;
        border: 1px solid transparent;
        cursor: pointer;
        padding: 8px 20px;
        //@include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius);
        @include transition(all .3s ease-in-out);
        &.btn-default{ background: $white; border: solid 1px $black-12; color: $black-54;
          &:hover , &:focus{
            box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.4); border: 1px solid $black-54;
          }
        }
        &.btn-reset{ background: $white; border: none; color: $black-54; padding: $padding5+3 $padding5;
          &:hover , &:focus{
            box-shadow: none; border: none;
          }
        }
        &.btn-primary{ background: $primary-color; color: $white;
            &:hover , &:focus{
                box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.4); border: 1px solid transparent;color: $white;
              }
        }
        &.btn-success{ background: $success-color; color: $white;
            &:hover , &:focus{
                box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.4); border: 1px solid transparent;color: $white;
              }
        }
        &:hover {
          color: $black-87;
          text-decoration: none;
        }
      
        &:focus,
        &.focus {
          outline: 0;
          box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.4);
        }
      
        // Disabled comes first so active can properly restyle
        &.disabled,
        &:disabled {
          opacity: 0.65; cursor: not-allowed;
          @include box-shadow(none);
          &:hover{@include box-shadow(none);}
        }
      
        &:not(:disabled):not(.disabled):active,
        &:not(:disabled):not(.disabled).active {
          @include box-shadow($btn-box-shadow);
      
          &:focus {
            @include box-shadow($btn-box-shadow);
          }
        }
        &.btn-link{padding: 0;color: $blue-color;
          &:hover,&:focus {
            color: $primary-color;@include box-shadow(none);
          }}
          
      }
      .btn-lg {
       padding: 12px 30px;
       font-size: $font16;
      }
      
      .btn-sm {
        padding: 4px 10px;
       font-size: $font12;
      }
      
      
      //
      // Block button
      //
      
      .btn-block {
        display: block;
        width: 100%;
      
        // Vertically space out multiple block buttons
        + .btn-block {
          margin-top: $margin30;
        }
      }
      .btn-toolbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;

  .input-group {
    width: auto;
  }
      }
        .btn-group { position: $position-relative; display: inline-flex; vertical-align: middle;
          .btn-default{
            &:hover, &:focus, &:active{z-index: 1; border:solid 1px rgba(0, 0, 0, 0.12); color:$white; background: rgba($primary-color, 1); box-shadow: none;}
          }
          // Prevent double borders when buttons are next to each other
          > .btn:not(:first-child),
          > .btn-group:not(:first-child) {
            margin-left: -1px;
          }
        
          // Reset rounded corners
          > .btn:not(:last-child):not(.dropdown-toggle),
          > .btn-group:not(:last-child) > .btn {
            border-top-right-radius: 0;
    border-bottom-right-radius: 0;
          }
        
          > .btn:not(:first-child),
          > .btn-group:not(:first-child) > .btn {
            border-top-left-radius: 0;
    border-bottom-left-radius: 0;
          }
          &.btn-group-small{
            .btn{padding:$padding0+2 $padding0+4;}
          }
        }
      
        .nav-tabs { 
          display: flex;
          flex-wrap: wrap;
          padding-left: 0;
          margin-bottom: $margin5*3;
          list-style: none;
          border-bottom: 1px solid $black-12;
      .nav-item {
        margin-bottom: -1px;
        cursor: pointer;
        color: $black-54;
        border-bottom: solid 3px transparent;
        padding: $padding5*3;
        position: $position-relative;
        justify-content: center;
        display: flex;
        font-weight: $fontmedium;
        text-transform: uppercase;
        letter-spacing: .0152857143em;
        
        &.active,
        &:hover,
        &:focus
        {color: $primary-color;
          border-bottom: solid 3px transparent;}
          &.active{font-weight: $fontmedium;border-bottom: solid 3px $primary-color;}
    }
  }

  /*btngroup and popover*/
  .btn-group{
       .popover{ top:30px;
        &.bs-popover-bottom{
          top:30px;
        }
        &.bs-popover-top{
          top: initial;
          bottom: 30px;
        }
        &.bs-popover-left{
          top: initial;
          bottom: 30px;
        }
        &.bs-popover-right{
          top: initial;
          bottom: 30px;
        }
      
    }
  }
  .switch-btn{
    position: $position-relative;
    display: inline-flex;
    vertical-align: middle;
    padding-left: $padding5*8;
    margin-bottom: $margin0;

    >input[type="checkbox"]{display: none;
        + .slider{ 
            position: $position-relative;
            cursor: pointer;
            width: 34px;
            height: 14px; 
            top: 4px;
            left: 0;
            margin-right: $margin5*2;
            background-color: $black-12;
            @include transition (all 0.3s);
            @include border-radius(32px);
            &:before{
                content: "";
                position: $position-absolute;
                background-color: #cccccc;
                top: -3px;
                left: -3px;
                width:20px;
                height: 20px;
                @include transition (all 0.5s);
            @include border-radius(50%);

            }
        }
        &:checked{
            + .slider{ background-color: rgba($primary-color, 0.3);
                &:before{
                    background-color: $primary-color;
                    @include transition (all 0.5s);
                    @include translate(22px, 0px)
    
                }
            }
        }
    }
    
.switch-label{vertical-align: top;}
}

@media(max-width:767px){
    .floating-btn {
      border-radius: 50%;
      content: "";
      width: 56px;
      height: 56px;
      /* text-indent: 999px; */
      overflow: hidden;
      white-space: nowrap;
      position: fixed;
      bottom: 15px;
      right: 15px;
      z-index: 99;
      font-size: 16px;
  }
  .navbar{overflow: hidden;overflow-x: auto;}
  .nav-tabs { 
    flex-wrap: nowrap;
    white-space:nowrap;}
}