.auction-header {
  background: #000;
  height: 48px;
  display: block;
  padding: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  z-index: 9;
  .logo {
    font-weight: bold;
    font-size: 22px;
    line-height: 26px;
    color: #fff;
  }
}
.right-panel-naviganion {
  display: flex;
  align-items: center;
  .nav {
    ul {
      display: flex;
      li {
        margin-right: 30px;
        height: 47px;
        &:last-child {
          margin-right: 26px;
        }
        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $white;
          height: 100%;
          display: flex;
          align-items: center;
          font-family: "Poppins", sans-serif;
          &:hover {
            color: #0bbddd;
          }
        }
        &.dropdownmenu {
          position: relative;
          a {
            &::after {
              content: "\e917";
              font-family: dc !important;
              font-size: 27px;
              padding-left: 5px;
            }
          }
        }
        &.active {
          a {
            color: #0bbddd;
          }
        }
      }
    }
  }
}
.header-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  .notification-btn {
    position: relative;
    padding-left: 23px;
    .notification-count {
      background: #0bbddd;
      height: 14px;
      width: 14px;
      border-radius: 100%;
      font-size: 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 11px;
      right: 6px;
      line-height: 15px;
    }
    &:before {
      content: "";
      height: 22px;
      width: 1px;
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      left: 0px;
      top: 12px;
    }
  }
  .user-detail {
    margin-left: 30px;
    ul {
      li {
        height: 47px;
        a {
          display: flex;
          align-items: center;
          height: 100%;
        }
        &.dropdownmenu {
          .submenu {
            right: 0px;
          }
        }
      }
    }

    .userarc {
      height: 26px;
      width: 26px;
      font-weight: bold;
      font-size: 14px;
      line-height: 21px;
      border-radius: 100%;
      background: $white;
      color: #0bbddd;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    .username {
      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: $white;
        display: block;
      }
      .user-degintion {
        font-weight: normal;
        font-size: 11px;
        line-height: 16px;
        display: block;
      }
    }
  }
  .language {
    ul {
      li {
        height: 47px;
        a {
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #fff;
          padding-left: 30px;
          height: 100%;
          display: flex;
          align-items: center;
        }
        &.dropdownmenu {
          position: relative;
          a {
            &::after {
              content: "\e917";
              font-family: dc !important;
              font-size: 27px;
              padding-left: 5px;
            }
          }
          .submenu {
            right: 0px;
          }
        }
      }
    }
  }
}
.user-detail {
  .dropdownmenu {
    a::after {
      content: "\e917";
      font-family: dc !important;
      font-size: 27px;
      padding-left: 5px;
      color: $white;
      position: relative;
      top: -7px;
    }
  }
}

.menupanel {
  li {
    &.dropdownmenu {
      position: relative;

      .submenu {
        position: absolute;
        background: #fff;
        color: #000;
        display: block;
        flex-direction: column;
        box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
        border-radius: 6px;
        min-width: 260px;
        z-index: 9;
        display: none;
        padding: 10px 0;
        li {
          display: block;
          padding-right: 0px;
          margin-right: 0px;
          height: auto;
          a {
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $heading-color;
            padding: 10px 20px;
            display: block;
            &::after {
              display: none;
            }

            &:hover {
              background: rgba(239, 242, 249, 0.5);
            }
          }
        }
      }
      &:hover {
        .submenu {
          display: block;
        }
        a {
          &::after {
            content: "\e91a";
            font-family: dc !important;
            font-size: 27px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}
