.loginContainer{position: $position-relative;
    width: $width-full;
    height: $height-full;}
.loginSection{background-image: url(/static/media/banner_2.557db1a3.png);
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    position: $position-fixed;
    left: 0;
    top: 0px;
    width: $width-full;
    height: $height-full;
    padding: $padding20;
}
.loginHeaderSec{ margin: $margin0;
    h1{color: $white; margin-bottom: $margin-bottom20;} 
}
.login_form {
    background-color: $bodywhite;
    @include border-radius(5px);
    padding: $padding20;
    width: 96%;
    margin: 0 auto;
    overflow: $overflow-hidden;
    @include box-shadow( 0 2px 10px rgba(0,0,0,.15));
    .form-control {
        height: 46px;
        font-size: $font16;
        padding: 10px 40px;
    }
}


@media(min-width:767px){
    
.login_form {
    width: 420px;
}
.loginHeaderSec{ margin: $margin30;
    h1{margin-bottom: $margin-bottom30;}
}
}