.auction-filter-card-outer {
    background: #ffffff;
    border-radius: 8px;
    margin-top: 20px;
    padding: 30px;
    .col-sm-6 {
        padding-left: 10px;
        padding-right: 10px;
    }
    .row {
        margin-right: -10px;
        margin-left: -10px;
        display: flex;
        flex-wrap: wrap;
    }
}

.inspected-tag{
    &.inspected-detail{
        position: relative;
        display: inline-block;
        margin-top: -158px;
        top: -4px;
        line-height: 20px;
        margin-left: 10px;
    }
}


.filter-cards {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-shadow: 0px 2px 4px rgba(36, 39, 44, 0.06);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    height: calc(100% - 20px);
    .filter-img {
        height: 140px;
        width: 215px;
        overflow: hidden;
        background: url(../../images/loader.gif) center center;
      //  background: #77391f;
        border-radius: 8px;
        .slick-slide {
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .slick-arrow {
            background: rgba($heading-color, 0.3);
            border-radius: 5px;
            z-index: 1;
            height: 34px;

            &.slick-prev {
                left: 0px;
                &::before {
                    content: "\e918";
                    font-family: dc !important;
                    color: #fff;
                    line-height: 20px;
                }
            }
            &.slick-next {
                right: 0px;
                &::before {
                    content: "\e919";
                    font-family: dc !important;
                    color: #fff;
                    line-height: 20px;
                }
            }
        }
    }
    .filter-card-data {
        font-family: "Poppins", sans-serif;
        padding-left: 40px;
        width: calc(100% - 215px);
        .card-heading {
            display: flex;
            align-items: center;
            cursor: pointer;
            span {
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                display: block;
                max-width: calc(100% - 24px);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            i {
                margin-left: 10px;
            }
        }
    }

    .filter-slider-outer {
        position: relative;
    }
}

.heading-bid-status {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bid-status {
    font-family: "Poppins", sans-serif;
    border-radius: 4px;
    font-weight: 500;
    font-size: 10px;
    padding: 0px 7px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    &.Reject-bg {
        background: #ff7e7e;
    }
    &.pending-bg {
        background: #f7b500;
    }
    &.inprogress-bg {
        background: #44d7b6;
    }
}
.filter-slider-img {
    img {
        width: 100%;
        object-fit: cover;
    }
}

.car-spec {
    display: flex;
    align-items: center;
    margin-top: 2px;
    li {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: rgba($heading-color, 0.7);
        padding: 0px 8px;
        position: relative;
        &:last-child {
            padding-right: 0px;
        }
        &:first-child {
            padding-left: 0px;
        }
        &::after {
            content: "";
            background-color: $heading-color;
            height: 2px;
            width: 2px;
            position: absolute;
            right: 0px;
            top: 8px;
        }
        &:last-child {
            &::after{
                display: none;
            }
        }
    }
}

.bid-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 16px;
    .bid-status-amt {
        display: flex;
        align-items: center;
        span {
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: $heading-color;
        }
        .bid-status {
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #fff;
            height: 20px;
            padding: 0px 5px;
            display: flex;
            align-items: center;
            margin-left: 10px;
            &.win-bg {
                background: #44d7b6;
                border-radius: 4px;
            }
            &.lose-bg {
                background: #FF7E7E;
                border-radius: 4px;
            }
        }
    }
    .bid-left {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
    }
}

.numric-btn {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .bid-btn {
        background: #0bbddd;
        border-radius: 5px;
        height: 42px;
        padding: 0px 24px;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #0bbddd;
        margin: 0px 8px;
        cursor: pointer;
    }
    .bid-btn:disabled {
        cursor: not-allowed;
    }
    .bid-icn {
        background: #ffffff;
        border: 1px solid #505050;
        box-sizing: border-box;
        border-radius: 5px;
        height: 42px;
        width: 43px;
        min-width: 43px;
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .bid-icn.disabled {
        cursor: not-allowed;
    }
    .bid-icn.view-report {
        color: #000;
    }
}
.pop-main-outer {
    .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.6);
        z-index: 9;

        .modal-main {
            position: fixed;
            background: white;
            width: 80%;
            height: auto;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        .close_icn {
            position: absolute;
            top: 20px;
            right: 20px;
            background: none;
            border: none;
            font-size: 21px;
            cursor: pointer;
        }
        .modal-body {
            max-height: 450px;
            overflow-y: auto;
            width: calc(100% + 80px);
            margin-left: -40px;
            padding: 0px 40px;
        }
    }
}
.react-numeric-input {
    b {
        background: transparent !important;
        border: 0px !important;
        width: auto !important;
        margin: 0px 19px !important;
    }
    .numaric-input {
        background: #ffffff;
        border: 1px solid #505050 !important;
        box-sizing: border-box !important;
        border-radius: 5px !important;
        height: 42px !important;
        width: 100% !important;
    }
}
.overflow-hidden {
    overflow: hidden;
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}
.auction-bid-popup {
    font-family: "Poppins", sans-serif;
    .modal {
        .modal-main {
            max-width: 462px;
            border-radius: 15px;
            padding: 40px;
        }
        .modal-header {
            display: block;
            padding: 0px;
            margin: 0px;
            border-bottom: 0px;
            padding-bottom: 20px;
        }
        h2 {
            font-weight: 600;
            font-size: 22px;
            line-height: 30px;
            text-align: left;
            display: block;
            border-bottom: 0px;
            margin: 0px;
            padding: 0px;
        }
    }
    .bid-amt-bx {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 15px;
        label {
            font-weight: normal;
            font-size: 13px;
            line-height: 19px;
        }
        .numaric-input {
            width: 212px !important;
        }
    }
    .bid-increament-txt {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        display: block;
        text-align: center;
        color: rgba($heading-color, 0.5);
        margin-top: 10px;
    }
    .bid-start-btn {
        background: #0bbddd;
        box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
        border-radius: 8px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        font-weight: 500;
        font-size: 13px;
        color: #fff;
        line-height: 19px;
        margin-top: 25px;
        border: 1px solid #0bbddd;
        cursor: pointer;
    }
}
.bookmark-icn {
    background: rgba(0, 0, 0, 0.3);
    height: 28px;
    width: 28px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 100%;
    color: #fff;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.bookmark-icn {
    .ic-favorite {
        color: #ff4a4a;
    }
}
.sold-txt {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0px;
    text-align: center;
    transform: translateY(-50%);
}

.mr-10{
    margin-right: 10px;
}