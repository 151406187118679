// .sprite{background-image:  url(../images/Sprites.png); background-repeat: no-repeat; display: inline-block; vertical-align: middle; background-size: 200px 150px;}
.ic-delete{width:8px; height: 8px; background-position: 0px 0px;}
.ic-close-w{width:8px; height: 8px; background-position: 0px 0px;}
.ic-close-b{width:8px; height: 8px; background-position: -37px 0px;}
.ic-rotate{width:8px; height: 8px; background-position: -9px 0px;}
.ic-help{width:14px; height: 14px; background-position: -19px 0px;}
.ic-kms{width:17px; height: 16px; background-position: -82px -15px;}
.ic-regdate{width:16px; height: 16px; background-position: 0px -34px;}
.ic-owner{width:16px; height: 16px; background-position: -34px -15px;}
.ic-fuel{width:16px; height: 16px; background-position: -18px -15px;}
.ic-regno{width:16px; height: 16px; background-position: -54px -34px;}
.ic-transmission{width:16px; height: 16px; background-position: -65px -15px;}
.ic-color{width:16px; height: 16px; background-position: 0px -15px;}
.ic-noreg{width:16px; height: 16px; background-position: -18px -34px;}
.ic-edit-gray{width:16px; height: 16px; background-position: 0px -53px;}
.ic-delete-gray{width:15px; height: 16px; background-position: -18px -53px;}
.ic-featured-gray{width:15px; height: 16px; background-position: -33px -53px;}
.ic-share-gray{width:16px; height: 16px; background-position: -66px -53px;}
.ic-report-gray{width:15px; height: 16px; background-position: -86px -53px;}
.ic-plus-gray{width:16px; height: 16px; background-position: -104px -53px;}
.ic-info{width:16px; height: 16px; background-position: -73px -98px;}
.ic-view{width:16px; height: 16px; background-position: -1px -98px;}
.ic-reopen{width:16px; height: 16px; background-position: -38px -98px;}
.ic-history{width:16px; height: 16px; background-position: -55px -98px;}
.ic-heart{width:16px; height: 16px; background-position: -73px -35px;}
.ic-copy{width:16px; height: 16px; background-position: -111px -15px;}
.ic-next-w{width:9px; height: 8px; background-position: -29px -137px;}
.ic-prev-w{width:9px; height: 8px; background-position: -19px -137px;}
.ic-next-b{width:9px; height: 8px; background-position: -10px -137px;}
.ic-prev-b{width:9px; height: 8px; background-position: -0px -137px;}
.ic-setting{width:16px; height: 16px; background-position: -139px -53px;}
.ic-subscribe{width:16px; height: 16px; background-position: -122px -53px;}
.ic-contact{width:16px; height: 16px; background-position: -157px -53px;}
.ic-save-b{width:16px; height: 16px; background-position: -175px -53px;}
.ic-save-w{width:16px; height: 16px; background-position: -175px -73px;}
.ic-date{width:16px; height: 16px; background-position: -111px -34px;}
.ic-rupees{width:16px; height: 16px; background-position: -132px -34px;}
.ic-plus{width:14px; height: 14px; background-position: -38px -118px;}
.ic-minus{width:14px; height: 14px; background-position: -58px -118px;}
.ic-rotate{width:14px; height: 14px; background-position: -19px -119px;}
.ic-fullscreen{width:14px; height: 14px; background-position: -0px -118px;}

.source-icon{background-image:  url(../images/sprite-gaadi.png); background-repeat: no-repeat; display: inline-block; vertical-align: middle;}
.WALK-IN, .Walk-in, .walk-in{background-position: 1px -150px; width: 25px;height: 20px;}
.Cardekho{background-position: 1px -31px;width: 50px;height: 13px;}
.Gaadi{background-position: 0px 0px;width: 50px;height: 14px;}
.cardekho_knowlarity{background-position: 1px -276px;width: 27px;height: 27px;}
.verified{background-position: 1px -55px;width: 22px;height: 22px;}
.CARWAL{background-position: 1px -86px;width: 24px;height: 14px;}
.website{background-position: 1px -123px;width: 18px;height: 18px;}
.CARTRADE{background-position: 1px -180px;width: 18px;height: 18px;}
.OLX{background-position: 1px -211px;width: 21px;height: 12px;}
.QUIKR{background-position: 1px -232px;width: 40px;height: 15px;}
.oto, .OTO{width: 25px;height: 10px; background-position: 0px -334px;}
.gcloud,.G-Cloud, .self, .SELF{width: 25px;height: 13px; background-position: 0px -346px;}
.zigwheel, .Zigwheels, .zigwheels, .ZIGWHEELS{width: 25px;height: 11px; background-position: 0px -360px;}
.carmudi, .CARMUDI{width: 36px;height: 10px; background-position: 0px -372px;}
// .oto{width: 25px;height: 10px; background-position: 0px 0px;}
// .gcloud{width: 25px;height: 13px; background-position: 0px -12px;}
// .zigwheel{width: 25px;height: 11px; background-position: 0px -26px;}

@media(min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5){
    // .sprite{background-image:  url(../images/Sprites-2x.png);background-size: 200px 150px;}
    .source-icon{background-image:  url(../images/sprite-gaadi-2x.png);background-size: 45px 398px;}
}
