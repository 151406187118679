// Base class

.tooltip {
  position: $position-absolute;
  z-index: $zindex-tooltip;
  display: $display-block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  //@include reset-text();
  font-size:$tooltip-font-size;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;
  min-width: 40px;

  &.show { opacity: $tooltip-opacity; }

  .tooltip-arrow {
    position: $position-absolute;
    display: $display-block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: $position-absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}
.tooltip-wrap{ display: $display-inline-block; position: $position-relative;
  .tooltip {display: none;}
  &:hover, &.active{.tooltip {display: $display-block; opacity: 0.9;}}
}

.bs-tooltip-top {bottom:0px;
  padding: $tooltip-arrow-height 0;

  .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -7px;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .tooltip-arrow {
    left: 0; top: 50%; margin-top: -7px;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .tooltip-arrow {
    top: 0;left: 50%;
    margin-left: -7px;

    &::before {
      bottom: 0;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .tooltip-arrow {
    right: 0;top: 50%; margin-top: -7px;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: 5px;
  color: $tooltip-color;
  text-align: center;
  min-width: 70px;
  background-color: $tooltip-bg;
  @include border-radius(3px);
}
