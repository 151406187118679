.dashboard{background: $bodycolor;padding: $padding5*3 $padding0;
.card{ margin-bottom: $margin-bottom30;
    .card-heading{background: rgba($headerbg,0.1); @include border-radius(5px 5px 0 0);}
}
}
.lead-list{ padding: $padding5*3 $padding0;
  li{ text-align: $text-center; 
    &:last-child{border-right: none;}  
    a{padding: $padding5*2 $padding5*2; display: $display-block;
  .leadno{font-size: $font20+12; margin-bottom: $margin0;}
  .leadlabel{font-size: $font14; color: $black-54;}
    }
}
}
.lead-bytime{ width: 100%; font-weight: $font-weight-normal; font-size: $font14;
  .css-yk16xz-control, .css-1pahdxg-control{
    border: none; background: none; height: 24px; min-height: inherit;
    @include box-shadow(none);
    
  .css-tlfecz-indicatorContainer,.css-1gtu0rj-indicatorContainer {padding: 2px; color:$blue-color;}
    .css-1uccc91-singleValue{font-size: $font14; color: $blue-color;}
  }
}
@media (min-width:$breakpoint-tab1) {
  
  .lead-list{ 
    li{  border-right: solid 1px $black-12;
      a{
      .leadno{font-size: $font20+12; }
      .leadlabel{font-size: $font16; }
      }
    }
  }
}
@media (min-width:$breakpoint-tab2) {
  .lead-bytime{ width:25%;}
}
@media (min-width:$breakpoint-pc1) {
  .lead-bytime{ width:32%;}
  .lead-list{ 
    li{ a{
      .leadno{font-size: $largefont - 10; }
    }
    }
  }
}
