.car-list{ font-size: $font14;
    >li{ border-bottom: solid 1px $black-12;
        >.wrap{position: $position-relative; padding: $padding5*3 $padding5;
            h2.carname{font-size: $font16; margin-bottom: $margin5}
            
            .img-box {
                .img_thumb{background: #f5f5f5; line-height: 170px;
                img{max-height: 170px;}
            }
            }
            .editprice{
                .input-group{
                    label{display: none;}
                }
            }
        }
        .actionbtn{display: none;}
        &:hover{
            >.wrap{ background: rgba($primary-color , 0.04);}
            .actionbtn{display: block;}
        }
     &:before,&:after{contain: ""; display: table;}
     &:after{clear: both;}   
    }
}
.carpoints{font-size: $font12; color: $black-54; margin-bottom:$margin5*3; padding-left: $padding5*3; min-height: 110px;
    >li{  list-style: disc; margin-bottom:$margin5;
        // &:before{display: $display-inherit; width: 5px; height: 5px; margin-right:$margin5+2; background: rgba($black , 0.12); content: "";
        // }
        // &:first-child{
        //     &:before{display: none;}
        // }
    }
}
.car-specs{margin-bottom: $margin5*2;
    >li{font-size: $font12; margin-bottom: $margin5; vertical-align: middle;display: flex;
        flex-direction: row;
        align-items: center;
        i{vertical-align: top !important; margin-right:$margin5;}
    .sprite{opacity: 0.5; margin-right:$margin5*2 }
}
}
.stock-listed{font-size: $font12; color: $black-54;margin-bottom: $margin5*3;
    >li{ 
        &:before{display: $display-inline-block;
            width: 5px;
            @include border-radius(5px);
            height: 5px;
            margin-right:$margin5*2;
            margin-left:$margin5*2;
            background: rgba($black , 0.12);
            content: "";
            color: $black-37;
            }
            &:first-child{
                &:before{display: none;}
            }
    }

}
.lead-circle{width: 90px;
    height: 90px;
    border: solid 5px $black-12;
    background:$white;
    @include border-radius(50%); @include transition (all 0.3s);
    position: $position-relative;
    display: $display-inline-block;
    color: $black-87;padding: $padding5*3 $padding5 $padding5*2 $padding5;
    text-align: $text-center;
        .pie_progress__number {font-size: $font20+2;}
        .pie_progress__label{font-size: $font12;}
        &:hover{background:$primary-color;color:$white;}
}
.editBtn{font-size: $font12; margin-left: $margin5*2;}

.img-box{position: $position-relative; border: solid 1px $black-12;  @include border-radius(3px); text-align: $text-center;
    padding: 1px;
    .link{color: $white;text-align: $text-center;font-size: $font12;background: $black-37; display: $display-block; cursor: pointer;}
    img{display: $display-inline-block;@include border-radius(3px); }
}
.inlinecheck{
    label{display: $display-inline-block; margin-right: $margin5*2;}
}
.img_thumb {
    padding: $padding0;
    position: $position-relative;
    width: $width-full;
    background: rgba($black, 0.1);
    line-height: 195px;
    text-align: $text-center;
    cursor: $cursor-pointer;
    // min-height: 200px;
}
.actionbtn{
    .btn{ text-align: $text-center; padding: $padding5 $padding5*2;
        i{ font-size: 20px; opacity: 0.87;}
        &:hover{i{opacity: 1;}
    }
    }
}
.btn-group {
    .btn{@include transition (all 0.3s);
        &:hover{
            i{background-position-y:-73px; }
        }
    }
}
.carmove .img-height{max-height: 126px}
@media(min-width:992px){
    .carpoints{ min-height: 110px;
        >li{ float: left; width: 50%; list-style: disc; margin-bottom:$margin5;
        }
    }
    #carstock{
        .carpoints{ min-height: initial; padding: 0px;
        //     >li{ 
        //         position: relative;
        //         width: auto;
        //         margin-left: 20px;
        //         &:before{ 
        //             display: inline-block;
        //             width: 3px;
        //             height: 3px;                            
        //             background: rgba(0, 0, 0, 0.12);
        //             content: "";
        //             @include border-radius(50%);
        //             position: $position-absolute;
        //             top: 7px;
        //             left:-10px;
        // }
        // &:first-child{margin-left: 0px;
        //     &:before{display: none;}
        // }
        //     }
        }
    }
}