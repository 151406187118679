fieldset{border-top: 1px solid $black-12;
    border-top-color:$black-12;
    margin: 0;
    padding: 0px 15px;
    margin-top: 20px;
    legend{
        display: $display-inline-block;
    padding: 5px 10px;
    font-size: $font14;
    width: $width-auto;
    font-weight: $font-weight-bold; color: $primary-color;
    border: none;
    }
}