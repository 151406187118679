[data-gs-csstooltip-wrapper$=tooltip] {
	[data-gs-csstooltip] {
		position: $position-relative;

		&:before, &:after {
			visibility: $visibility-hidden;
			opacity: $opacity0;
			pointer-events: $pointer-events-none;
			@include translate (-50%, 10px);
			@include transition(all .25s ease-out);
		}
		&:before {
			position: $position-absolute;
			@include bottom(120%);
			@include left(50%);
			margin-bottom: $margin5;
			// margin-left: auto;
			padding: $padding5+2;
			width: auto;
			max-width: 160px;
			@include border-radius(3px);
			background-color: $black;
			color: $white;
			content: attr(data-gs-csstooltip);
			text-align: $text-center;
			font-size: $font12;
			line-height: $line-height-base;
			text-transform: capitalize;
		}
		&:after {
			position: $position-absolute;
			@include bottom(120%);
			@include left(50%);
			margin-left: 0px;
			width: 0;
			@include border-top(5px solid #000);
			@include border-right(5px solid transparent);
			@include border-left(5px solid transparent);
			content: "";
			font-size: $font14 - 14px;
			line-height: 0px;
		}
		&:hover {
			&:before, &:after {
				visibility: $visibility-visible;
				opacity: $opacity1;
				@include translate(-50%, 0px);
			}
		}
	}
}