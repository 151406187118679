/* Style the accordion section */
.accordion__section {
    background-color:$white;
    display: flex;
    position: $position-relative;
    flex-direction: column;
    border: 1px solid rgba(0,0,0,0.12);
    @include border-radius(3px);
    margin-bottom: $margin5;
  
    .accordion {
        background-color:rgba($black , 0.02);
        color:$black-87;
        cursor: pointer;
        display: flex;
        align-items: center;
        border: none;
        outline: none;
        transition: background-color 0.6s ease;        
        position: relative;        
        &.accordion:hover{
            background-color:rgba($black , 0.03);
        }
        
        &:before{content:"+"; position: $position-absolute; right:10px; top:7px; color:$black-54; font-size: $font20;}
        &.active {background-color:rgba($black , 0.02);
            &:before{content:"–"; }
            .accordion__title {
              // border-bottom: 1px solid rgba(0,0,0,0.12);
              
            }
            + .accordion__content{padding: $padding5*3;@include box-shadow(0px 3px 6px 0px rgba($black, 0.3));@include border-radius(3px);

            }
        }
    }
    .accordion__title {
        font-weight: $fontmedium;
        font-size: $font16;
        padding: $padding5*2;
        display: $display-block;
        width:$width-full;
        
        
      }
      
      /* Style the accordion chevron icon */
      .accordion__icon {
        margin-left: auto;
        transition: transform 0.3s ease;
        &.rotate {
          transform: rotate(90deg);
        }
      }
}


.accordion__content {
  background-color: white;
  
  padding: $padding0;
  transition: max-height 0.3s ease;
}

/* Style the accordion content text */
.accordion__text { padding-bottom:$padding5*3;  margin-bottom: $margin5*3;
}
.accrdian-bt0{
  .accordion__text{margin-bottom: $margin0; padding-bottom: $padding0;}
}