.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  
    &.hinge {
      animation-duration: 2s;
    }
  }@keyframes fadeIn {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation-name: fadeIn;
  }
  
  @keyframes fadeInDown {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInDown {
    animation-name: fadeInDown;
  }
  
  @keyframes fadeInDownBig {
    0% {
      opacity: 0;
      transform: translateY(-500px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInDownBig {
    animation-name: fadeInDownBig;
  }


@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUp {
    animation-name: fadeInUp;
  }
  
  @keyframes fadeInUpBig {
    0% {
      opacity: 0;
      transform: translateY(500px);
    }
  
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .fadeInUpBig {
    animation-name: fadeInUpBig;
  }