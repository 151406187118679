@import 'inventorylist';

.search-wrap{padding: $padding5*3; position: $position-relative; margin-bottom: $margin5*3;
    @include border-radius(4px);
    @include box-shadow(0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12));
    background: $white; 
    
   .row{margin-right: -$padding5;
    margin-left:-$padding5;
    .col-sm-6,.col-md-2,.col-xs-6, .col-xs-12{padding-right: $padding5;
        padding-left:$padding5;
    // &:first-child{
    //     padding-left:$padding5*2;}
    }
   }
   label{font-size: $font12; color: $black-54;} 
   .css-1okebmr-indicatorSeparator{display: $display-none;}
   .css-yk16xz-control,.css-1pahdxg-control {height: 38px;}
   .css-1wa3eu0-placeholder{font-size: $font12; color: $black-54;}
.css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{padding: 8px 4px;}
.advancesearch{font-size: $font12; 
position: $position-absolute;left:50%;bottom:-23px;background: $white; @include border-radius(0 0 100px 100px);
    margin-left: -120px; padding: $padding0+2 $padding5*3;@include box-shadow(0 2px 1px -1px rgba(0,0,0,.2));
    opacity: 1;

}
}
.search-flex{
    display: flex;flex-direction: row;flex-wrap: wrap;
.searchitems{width: 100%; padding: $padding0 $padding5;
    }
}
@media (min-width:$breakpoint-tab1){
    .search-wrap{padding-bottom: $padding0;}
    .search-flex{
        display: flex;flex-direction: row;flex-wrap: wrap;
    .searchitems{width: 20%;
        
        }
    }
}
@media (min-width:$breakpoint-tab2){
    .search-flex{
        display: flex;flex-direction: row;flex-wrap: wrap;
    .searchitems{width: 14.285;
        
        }
    }
}
@media (min-width:$breakpoint-pc1){
    
}
@media (min-width:$breakpoint-pc2){
    
}
.manageStock {
    h1{font-size: $font14*2; padding-top:$padding5*3;}
}
.mrg-r10{margin-right: $margin5*2;}

/* Manage Result*/
.result-wrap{padding: $padding5*3 $padding0; position: $position-relative;}
.tagsphotos{ margin-bottom: $margin5*3;
    textarea{min-height: 30px;height: 34px;
        margin-bottom: 5px;}
}
.tagscol{margin-left: -$margin5;margin-right:-$margin5;
>div{padding-left:$padding5; padding-right: $padding5; }}

.similarlist{
    >li {position: $position-relative;
        .thumbnail{background-color: $white; position: $position-relative;
            border: solid 1px $black-12;
            @include border-radius(2px);
            display: $display-block;
            margin-bottom:$margin5*3;
            padding: $padding0;
            @include transition(border 0.2s ease-in-out 0s);
            .caption {
                padding: $padding5*2;
                color: $black-87;
                .carpoints{min-height: inherit; padding-left: $padding0;
                    li{position: relative;
                        padding-left: 10px;
                        width: 50%;
                        margin-left: 0px;
                        
                        &:before{
                            display: inline-block;
                            width: 3px;
                            height: 3px;                            
                            background: rgba(0, 0, 0, 0.12);
                            content: "";
                            @include border-radius(50%);
                            position: $position-absolute;
                            top: 7px;
                            left:0px;
                        }
                    //     &:first-child{padding-left:0px; margin-left: 0px;
                    //         &:before{display: none;}
                    // }
                    }
                }
            }
        
            .img_thumb{line-height: 135px; 
                img{max-height:135px;}
            }
        }
        .selectCheckbox {
            background-color: $white;
            padding: 3px 8px 7px 7px;
            position: absolute;
            left: 0px;
            top: 0px;
            border-right: solid 1px #d5d5d5;
            border-bottom: solid 1px #d5d5d5;
            .form-field{ margin-bottom:$margin0; }
            .gs_control{padding-left: 16px; display: initial;}
        }
    }
}
.table{
    .form-control{font-size: $font14;}
}
.car-price{margin-bottom: 5px;}
@media (min-width:768px)
{

.similarlist{
    >li {
        .thumbnail{min-height: 280px;
        &:hover{
            @include box-shadow(0px 4px 16px 0px rgba($black, 0.2));
        }
        .caption{
            p{
                overflow: $overflow-hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: $margin5;
            }
        }
    }
    }
}
}
@media (max-width:767px){
    .search-wrap{
        display: none;
        position: fixed;
        bottom: 0;
        z-index: 999;
        left: 0;
        width: 100%;
        height: 100%;
        margin-bottom: 0;
        overflow: hidden;
        overflow-y: auto;
        border-radius: 0;
        border: 0;
        .btn.btn-primary{width:calc(50%)}
        .btn.btn-default{width:calc(50% - 10px)}
        .advancesearch{position: initial; margin-top:$margin5*3; }
    }
    // .search-wrap:before{
    //     content: "";
    //     width:100%;
    //     height:56px;
    //     @include box-shadow(2px 0px 4px 0px rgba($black, 0.3));
    //     display: $display-block;
    //     position: fixed;
    //     top: 0;
    //     left: 0;
    //     z-index: 1000;
    //     background: $white;


    // }
    .search-wrap:before{
        content: "\e908";
        width:24px;
        height:24px;
        font-family: 'dc' !important;
        
        z-index: 1000;
        font-size: 24px;}

        .manageStock { padding: $padding0;
            h1{font-size: $font14+10; padding-top:$padding5*2; padding-left:$padding5*3; margin-bottom: $margin5;}
            .card{ border: none; @include box-shadow(none);
                .card-body{background: $bodycolor;
                .car-list{
                    >li{
                        background: $white; 
                        margin-bottom: $margin5*3;
                        @include border-radius(3px);
                        @include box-shadow(0px 2px 4px 0px rgba($black, 0.3));
                        .wrap{
                            padding: $padding0;
                            .col-sm-10{
                                >.row{padding: $padding5*3;}
                        }
                        }
                        .actionbtn{display: $display-block;}
                    }
                    .lead-circle{margin-bottom: $margin5*3;}
                }
            }
            }
        }
}
.num-words{text-transform: capitalize;opacity: 0.87;font-size: $font12;}

.reason {display: none;
&.active{display: block;}
}
.imgRejectReasons{ 
    color: $error-color; 
    background: rgba($error-color, 0.03); 
    border: solid 1px rgba($error-color, 0.12); 
    padding: 5px;}
.reasonlist{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    list-style: outside;
    padding-left: 20px;

    li{display: list-item;
        width: 25%;
        list-style: disc;
        font-size: 12px;
        margin-bottom: 7px;
    
    }
}

.tagsphotos{
    .recent-list-img{
        background: #f5f5f5;
        border: solid 1px $black-12;
        display: flex;
        justify-content: center;
        img{height:80px;}
    }
}

// @media (min-width:1600px){
//     .carpoints{
//         li{position: relative;
//             padding-left: 10px;
//             width: auto !important;
//             margin-left: 0px;
//             &:before{
//                 display: inline-block;
//                 width: 3px;
//                 height: 3px;                            
//                 background: rgba(0, 0, 0, 0.12);
//                 content: "";
//                 @include border-radius(50%);
//                 position: $position-absolute;
//                 top: 7px;
//                 left:0px;
//             }
//         }
//     }
// }
