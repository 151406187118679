@font-face {
  font-family: "dc";
  src: url("../fonts/dc.eot?qdj8fm");
  src: url("../fonts/dc.eot?qdj8fm#iefix") format("embedded-opentype"), url("../fonts/dc.ttf?qdj8fm") format("truetype"),
    url("../fonts/dc.woff?qdj8fm") format("woff"), url("../fonts/dc.svg?qdj8fm#dc") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic-"],
[class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "dc" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  // line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.ic-bid-icn:before {
  content: "\e960";
}
.ic-notification:before {
  content: "\e961";
  color: #fff;
}
.ic-info-icon:before {
  content: "\e95f";
}
.ic-error:before {
  content: "\e900";
}
.ic-replay:before {
  content: "\e901";
}
.ic-call:before {
  content: "\e902";
}
.ic-chat:before {
  content: "\e903";
}
.ic-email:before {
  content: "\e904";
}
.ic-location_onplaceroom:before {
  content: "\e905";
}
.ic-alternate_email:before {
  content: "\e906";
}
.ic-add:before {
  content: "\e907";
}
.ic-clearclose:before {
  content: "\e908";
}
.ic-content_copy:before {
  content: "\e909";
}
.ic-createmode_editedit:before {
  content: "\e90a";
}
.ic-filter_list:before {
  content: "\e90b";
}
.ic-remove:before {
  content: "\e90c";
}
.ic-save:before {
  content: "\e90d";
}
.ic-save_alt:before {
  content: "\e90e";
}
.ic-access_alarms:before {
  content: "\e90f";
}
.ic-access_timequery_builderschedule:before {
  content: "\e910";
}
.ic-brightness_highbrightness_7:before {
  content: "\e911";
}
.ic-gps_not_fixedlocation_searching:before {
  content: "\e912";
}
.ic-attach_file:before {
  content: "\e913";
}
.ic-insert_drive_file:before {
  content: "\e914";
}
.ic-insert_photoimagephoto:before {
  content: "\e915";
}
.ic-folder:before {
  content: "\e916";
}
.ic-keyboard_arrow_down:before {
  content: "\e917";
}
.ic-keyboard_arrow_left:before {
  content: "\e918";
}
.ic-keyboard_arrow_right:before {
  content: "\e919";
}
.ic-keyboard_arrow_up:before {
  content: "\e91a";
}
.ic-color_lenspalette:before {
  content: "\e91b";
}
.ic-crop:before {
  content: "\e91c";
}
.ic-panorama_fish_eyeradio_button_unchecked:before {
  content: "\e91d";
}
.ic-remove_red_eyevisibility:before {
  content: "\e91e";
}
.ic-add_a_photo:before {
  content: "\e91f";
}
.ic-directions_car:before {
  content: "\e920";
}
.ic-arrow_back:before {
  content: "\e921";
}
.ic-arrow_drop_down:before {
  content: "\e922";
}
.ic-arrow_drop_down_circle:before {
  content: "\e923";
}
.ic-arrow_drop_up:before {
  content: "\e924";
}
.ic-arrow_forward:before {
  content: "\e925";
}
.ic-cancel:before {
  content: "\e926";
}
.ic-check:before {
  content: "\e927";
}
.ic-fullscreen:before {
  content: "\e928";
}
.ic-fullscreen_exit:before {
  content: "\e929";
}
.ic-menu:before {
  content: "\e92a";
}
.ic-keyboard_control:before {
  content: "\e92b";
}
.ic-more_vert:before {
  content: "\e92c";
}
.ic-refresh:before {
  content: "\e92d";
}
.ic-arrow_back_ios:before {
  content: "\e92e";
}
.ic-arrow_forward_ios:before {
  content: "\e92f";
}
.ic-notifications:before {
  content: "\e930";
}
.ic-person:before {
  content: "\e931";
}
.ic-person_add:before {
  content: "\e932";
}
.ic-share:before {
  content: "\e933";
}
.ic-check_box:before {
  content: "\e934";
}
.ic-check_box_outline_blank:before {
  content: "\e935";
}
.ic-radio_button_checked:before {
  content: "\e936";
}
.ic-stargrade:before {
  content: "\e937";
}
.ic-bookmarkturned_in:before {
  content: "\e938";
}
.ic-delete:before {
  content: "\e939";
}
.ic-favorite:before {
  content: "\e93a";
}
.ic-favorite_outline:before {
  content: "\e93b";
}
.ic-search:before {
  content: "\e93c";
}
.ic-settings:before {
  content: "\e93d";
}
.ic-date_range:before {
  content: "\e93e";
}
.ic-cd_R:before {
  content: "\e93f";
}
.ic-cd-Dealer2:before {
  content: "\e940";
}
.ic-cd-fuel:before {
  content: "\e941";
}
.ic-cd-kilometer:before {
  content: "\e942";
}
.ic-cd-owner:before {
  content: "\e943";
}
.ic-cd-Transmission:before {
  content: "\e944";
}
.ic-cd-year:before {
  content: "\e945";
}
.ic-colors:before {
  content: "\e946";
}
.ic-compare-car:before {
  content: "\e947";
}
.ic-india-no:before {
  content: "\e948";
}
.ic-new-car:before {
  content: "\e949";
}
.ic-rc-file:before {
  content: "\e94a";
}
.ic-subscription:before {
  content: "\e94b";
}
.ic-Call-history:before {
  content: "\e961";
}
.ic-cd-featured:before {
  content: "\e94c";
}
.ic-cd-un-featured:before {
  content: "\e94d";
}
.ic-rupees-line:before {
  content: "\e94e";
}
.ic-excel-file:before {
  content: "\e94f";
}
.ic-image-file:before {
  content: "\e950";
}
.ic-pdf-file:before {
  content: "\e951";
}
.ic-word-file:before {
  content: "\e952";
}
.ic-file-history:before {
  content: "\e95b";
}
.ic-whatsapp:before {
  content: "\e953";
}
.ic-blur_circular:before {
  content: "\e95a";
}
.ic-blur_on:before {
  content: "\e954";
}
.ic-rotate_left:before {
  content: "\e955";
}
.ic-rotate_right:before {
  content: "\e956";
}
.ic-stars:before {
  content: "\e957";
}
.ic-filter_list_alt:before {
  content: "\e95c";
}
.ic-peson-blacklist:before {
  content: "\e958";
}
.ic-peson-listed:before {
  content: "\e959";
}
.ic-engine:before {
  content: "\e95d";
}
.ic-bump-up:before {
  content: "\e95e";
}
