footer{  //background:$white;
    .footer-container {
        height: $height-auto;
        color:$black-37;
        font-size: $font12;
        padding: $padding5*3;
    }
    ul.footer-dealer  {
        li{
        display: $display-inline-block;
        padding: 0px 5px;
        
        }
    }
    span{padding-right: 5px;padding-left: 5px;display: $display-inline-block;}
    &.footer-fixed{
        position: $position-fixed;
        bottom:0;
        left:0;
        width:$width-full;
    }
}
@media(min-width:768px){
    .page-minheight{min-height:500px }
}
@media(min-width:1200px){
    .page-minheight{min-height:550px }
}