.tabs-list {
  ul {
    border-bottom: 0px;
    background: #fff;
    padding: 20px 30px;
    border-radius: 8px;
    li {
      margin-right: 40px;
      button {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: rgba(0, 0, 0, 0.87);
        // padding-bottom: 5px;
        border-bottom: 2px solid transparent;
        font-family: "Poppins", sans-serif;

        background: none!important;
        border: none;
        padding: 0 0 5px 0;
       
        cursor: pointer;

        &:last-child {
          margin-right: 0px;
        }
        &.active {
            border-bottom: 2px solid #0bbddd;
            color: #0bbddd;
            font-weight: 600;
        }
      }
      &.active {
        button {
          border-bottom: 2px solid #0bbddd;
          color: #0bbddd;
          font-weight: 600;
        }
      }
    }
  }
  .car-spec {
    padding: 0px;
    li {
      margin-right: 0px;
    }
  }
}

.w-100{
  width: 100%;
}

.auctionTabCard {
  .infinite-scroll-component{
    display: flex;
    flex-wrap: wrap;
  } 
}

.auction-outer{

    .nav-tabs {
        .nav-item {
            padding: 0;
        }
    }
}