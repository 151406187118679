.pagesteps{ 
    padding: $padding5*3;
    background-color:$white;
    margin-bottom:$margin5*3;
    @include box-shadow(0 0 4px rgba(0, 0, 0, 0.5)); 
    display: flex;
    flex-direction: row;
    justify-content: left; position: relative;
    

    li{display: $display-inline-block;position: $position-relative;
        margin-right: $margin5*6;
        @include transition(all 0.3s);
        &:before{
            content: "";
            display: $display-block;
            height: 1px;
            width: 100%;
            border-bottom: 2px solid #ebebeb;
            float: left;
            margin-top: 15px;
            position: absolute;
            right: -100%;
        }
        &:last-child{
            &:before{display: none;}
        }
        a{
            @include border-radius(16px);
            height: 32px;
            text-align: $text-center;
            background-color:$white;
            border: solid 1px $black-12;
            color:$black-54;
            padding: 0px 10px 0 0;
            float: left;
            @include transition(all 0.3s);

            .pagecount{
                display: $display-inline-block;
                width:32px;
                height: 32px;
                @include border-radius(50%);
                text-align: $text-center;
                line-height: 32px;
                background: #cccccc;
                margin: -1px 8px 0px -1px;@include transition(all 0.3s);
            }
            &.active, &:hover{
                background-color:#fae0d8;
                    border: solid 1px rgba($primary-color,0.3);
                    .pagecount{
                        background-color:$primary-color;
                        color: $white;
                    }
                
            }
        }
       
    }
}
.btn{
    .ic-next-w, .ic-prev-b{margin-right: $margin5;}
}


.map-wrap{border: solid 1px $black-12; min-height: 600px;background: url(../images/mapscreen.png) no-repeat; background-size: cover;}
.week-day{padding-top:$padding5*5;}
#popoverInventory{left: -80px;
    .arrow{left: 86px;}
}