.auction-table-data {
  background: #fff;
  border-radius: 8px;
  table {
    table-layout: fixed;
    font-family: "Poppins", sans-serif;
    width: 100%;
    thead {
      tr {
        background: #e3e7f1;
        border-radius: 6px;
        th {
          padding: 15px 30px;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: $heading-color;
          white-space: nowrap;
          text-align: left;
          &:first-child {
            border-radius: 8px 0 0 0px;
          }
          &:last-child {
            border-radius: 0px 8px 0px 0;
          }
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid rgba($heading-color, 0.06);
        cursor: pointer;
        td {
          padding: 15px 30px;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          color: rgba($heading-color, 0.6);
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          .time-left {
            color: $heading-color;
          }
          .closded-before {
            color: #ff0000;
          }
        }
        &:hover {
          background: rgba(239, 242, 249, 0.4);
        }
      }
    }

    th,
    td {
      &:nth-child(1) {
        width: 30%;
      }
      &:nth-child(2) {
        width: 18%;
      }
      &:nth-child(3) {
        width: 18%;
      }
      &:nth-child(4) {
        width: 18%;
      }
      &:nth-child(5) {
        width: 16%;
        font-weight: 500;
        font-size: 13px;

        i {
          font-weight: bold;
        }
      }
    }
  }
}
