fieldset {
  min-width: 0;
  padding: 0;
  margin: $margin0;
  border: 0;
}

legend {
  display: $display-block;
  width: $width-full;
  padding: $padding0;
  margin-bottom: $margin-bottom20;
  font-size: $font16;
  line-height: inherit;
  color: $black-87;
  border: 0;
  border-bottom: 1px solid $black-12;
}

label {
  display: $display-inline-block;
  max-width: $width-full;
  margin-bottom: $margin0 + 3;
  opacity: 0.87;
}

input {
  @include box-sizing(border-box);
}
input[type="search"] {
  @include box-sizing(border-box);
  -webkit-appearance: none;
  appearance: none;
}

// Position radios and checkboxes better
input[type="radio"],
input[type="checkbox"] {
  margin: 4px 0 0;
  margin-top: 1px \9; // IE8-9
  line-height: normal;

  &[disabled],
  &.disabled,
  fieldset[disabled] & {
    cursor: $cursor-disabled;
  }
}

input[type="file"] {
  display: $display-block;
}

// Make range inputs behave like textual form controls
input[type="range"] {
  display: $display-block;
  width: $width-full;
}

// Make multiple select elements height not fixed
select[multiple],
select[size] {
  height: $height-auto;
}

// Focus for file, radio, and checkbox
input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
  border-color: $black-54;
  @include box-shadow(0 0 4px rgba(0, 0, 0, 0.5));
}
.form-control {
  display: $display-block;
  width: $width-full;
  height: 38px; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
  padding: 5px 10px;
  font-size: $font16;
  line-height: $line-height-base;
  color: $input-color;
  background-color: $white;
  background-image: none;
  border: 1px solid rgba(0,0,0,0.30);
  @include border-radius(3px); 
  @include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);outline: none;
  
  &:hover, &:focus, &:active{border: 1px solid $black-54;}
  &:focus, &:active{border: 1px solid #2684FF; box-shadow: 0px 0px 0px 1px #2684FF;}

  &:disabled,
  &[readonly] {
    background: $black-12;
    opacity: 0.4;
    cursor: $cursor-not-allowed;
    &:hover,
    &:focus {
      @include box-shadow(none);
      border: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
}

.form-group {
  margin-bottom: $margin-bottom20;
  position: $position-relative;
}
.error,
.form-error {
  color: $error-color;
  font-size: $font12;
  display: $display-none;
  position: absolute;
}

textarea {
  &.form-control {
    height: auto;
    min-height: 60px;
    resize: vertical;
  }
}

/* Actual code example */

.form-field {
  position: $position-relative;
  margin-bottom: 20px;
  input {
    &:focus {
      + label {
        color: $primary-color !important;
      }
    }
  }
  input + label {
    position: absolute;
    top: 7px;
    left: 15px;
    font-size: $font14;
    line-height: 24px;
    color: $black-54;
    opacity: 1;
    pointer-events: none;
    transition: 0.1s all ease-in-out;
  }

  &.active {
    input + label {
      top: -12px;
      opacity: 1;
      left: 10px;
      font-size: $font12;
      background: $white;
      padding: 0 5px;
      color: $black-37;

      &.error {
        color: #ec392f;
      }
    }
  }
}
.form-field input::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: rgba($black, 0.54);
  font-size: $font12;
}
.form-field input::-moz-placeholder,
input::-webkit-input-placeholder {
  color: rgba($black, 0.54);
  font-size: $font12;
}
.form-field input:-ms-input-placeholder,
input::-webkit-input-placeholder {
  color: rgba($black, 0.54);
  font-size: $font12;
}
.form-field input:-moz-placeholder,
input::-webkit-input-placeholder {
  color: rgba($black, 0.54);
  font-size: $font12;
}

.form-field p.predicted {
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #e0e0e0;
  opacity: 1;
  pointer-events: none;
}
.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;

  > .form-field {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
    display: flex;

    + .form-control,
    + .custom-select,
    + .custom-file {
      margin-left: -1px;
    }
    .form-control {
      border-radius: 3px 0px 0px 3px;
    }
  }
  > .form-control:focus,
  > .custom-select:focus,
  > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }
  > .form-control,
  > .custom-select {
    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.input-group-prepend,
.input-group-append {
  display: flex;

  // Ensure buttons are always above inputs for more visually pleasing borders.
  // This isn't needed for `.input-group-text` since it shares the same border-color
  // as our inputs.
  .btn {
    position: relative;
    z-index: 2;
    border-radius: 0px 3px 3px 0px;

    &:focus {
      z-index: 3;
    }
  }
}
.input-group-text {
  display: flex;
  align-items: center;
  padding: $padding5;
  margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
  font-size: $font12; // Match inputs
  font-weight: $font-weight-normal;
  // line-height: $input-line-height;
  color: $black-54;
  text-align: center;
  white-space: nowrap;
  background-color: rgba($black, 0.03);
  border: 1px solid $black-12;
  @include border-radius(3px);

  // Nuke default margins from checkboxes and radios to vertically center within.
  input[type="radio"],
  input[type="checkbox"] {
    margin-top: 0;
  }
}
.input-group-prepend {
  margin-right: -1px;
  .input-group-text {
    border-radius: 3px 0px 0px 3px;
  }
}
.input-group > .form-field:not(:first-child),
.input-group > .form-control:not(:first-child),
.input-group > .form-field:not(:first-child) .form-control {
  border-radius: 0 3px 3px 0px;
}
.css-1hwfws3{font-size: $font16;}
.css-2b097c-container, .css-1pahdxg-control, .css-yk16xz-control{
  .css-1okebmr-indicatorSeparator{
    display: none;
  }
  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer{padding:8px 4px;}
.react_select__indicator-separator {
  display: none;
}
.react_select__indicator {
  padding: 8px 4px;
}

.error-field .form-control,
.field-error .form-control,
.field-error .css-yk16xz-control {
  border: solid 1px rgba(255, 0, 0, 0.37);
  background: rgba(255, 0, 0, 0.02);
}
.error-field .error,
.field-error .error {
  color: $error-color;
}
.react-datepicker__input-container {
  input {
    background-image: url(../images/date-icon.png);
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 21px 16px;
    padding-right: $padding5 * 5;
  }
}
.rupees-field {
  input {
    background-image: $rupeesImgUrl;
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 21px 16px;
    padding-left: $padding5 * 5;
  }
}
@media (min-resolution: 144dpi), (-webkit-min-device-pixel-ratio: 1.5) {
  .react-datepicker__input-container {
    input {
      background-image: url(../images/date-icon-2x.png);
    }
  }
  .rupees-field {
    input {
      background-image: $rupeesImgUrl2x;
    }
  }
}
