.auction-footer {
    padding: 30px 0;
    .footer-txt {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copyright-txt {
            font-weight: normal;
            font-size: 12px;
            line-height: 14px;
            color: rgba($heading-color, 0.4);
        }
    }
    .footer-link {
        display: flex;
        align-items: center;
        li {
            padding-left: 30px;
            position: relative;
            a {
                font-weight: normal;
                font-size: 12px;
                line-height: 14px;
                color: rgba($heading-color, 0.4);
            }
            &:first-child {
                padding-right: 30px;
                &::after {
                    content: "";
                    width: 1px;
                    height: 17px;
                    background: rgba(0, 0, 0, 0.2);
                    position: absolute;
                    right: 0px;
                    top: 2px;
                }
            }
        }
    }
}
