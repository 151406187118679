.gs_control {
	display: $display-block;
	text-align: $text-left;
	position: $position-relative;
	padding-left: $padding5*5;
	margin-bottom: $margin5*3;
	cursor: $cursor-pointer;

	font-size:$font14;
	

	&:hover input~.gs_control__indicator {
		background:transparent; border: solid 1px $black-54;
	}

	&:hover input:not([disabled]):checked~.gs_control__indicator {
		background: $primary-color;border: solid 1px $primary-color;
	}

	input {
		position: $position-absolute;
		@include z-index(1);
		@include opacity(0); top: 0;left: 0; height: initial;

		&:focus~.gs_control__indicator {
			background:transparent;
		}

		&:checked~.gs_control__indicator {
			background: $primary-color;border: solid 1px $primary-color;
		}

		&:checked:focus~.gs_control__indicator {
			background: $primary-color;
		}

		&:disabled~.gs_control__indicator {
			background: #e6e6e6;
			@include opacity(0.6) ;
			pointer-events: none;
		}

		&:checked~.gs_control__indicator:after {
			display: $display-block;
		}

	}

}
.rc-inline{
	.gs_control, .form-field{ display: $display-inline-block; margin-right: $margin5*6;}
}

.gs_control__indicator {
	position: $position-absolute;
	@include top(2px);
	@include left(0px);
	height: 16px;
	width: 16px;
	background:transparent;
	border: solid 1px $black-54;
	@include border-radius(2px);
	@include transition(default);

	&:after {
		content: '';
		position: $position-absolute;
		display: $display-none;
	}

}

.gs_checkbox {
	.gs_control__indicator {
		&:after {
			@include left(5px);
			@include top(1px);
			width: 5px;
			height: 10px;

			@include border(solid $white);
			border-width:0 2px 2px 0;
			@include rotate (45);
		}

	}

	input:disabled~.gs_control__indicator:after {
		border-color: #7b7b7b
	}

}

.gs_radio {
	.gs_control__indicator {
		@include border-radius(50%);

		&:after {
			@include left(4px);
			@include top(4px);
			height: 6px;
			width: 6px;
			@include border-radius(50%);
			background: $white;
		}

	}

	input:disabled~.gs_control__indicator:after {
		background: #7b7b7b
	}

}





