.buyerenguiry-table{ color: $black-54;
    .btn{
        &.btn-link{font-size: $font12; color: $black-54;}
    }
    tr{&:hover{
        .btn{
            &.btn-link{ color: $primary-color;}
            &.updatelead{ color: $white;background: $primary-color;}
        }
    }
    td{width:19%;
        &:nth-child(3),&:nth-child(4){width: 15%;}
    &:last-child{width:13%;}
    #customer_name_{.source-icon{margin-left: $margin5;}}
    }
}
    .css-1hwfws3,  .css-1uccc91-singleValue{
        font-size: $font14 !important;
    }    


}
.car-list{
    >li{padding: $padding0; text-align: $text-left;
        
    }
    
}
.carname{font-size: $font16; margin-bottom: $margin5;}
.closerow{position: $position-absolute;
    z-index: 99; right:15px; top: 12px;
    background:$black-54; border-radius: 3px; display: block; width:24px; height: 24px; line-height: 24px; 
    text-align: $text-center; color: $white; font-size: $font20; cursor: $cursor-pointer;@include transition(all 0.3s);
&:hover{background:$black-87;}
}
.fav-car{position: $position-absolute; top: 5px;right: 5px; font-size: $font20;line-height:20px;
&.active{
    .ic-heart{ background-position: -92px -35px;}
    }
}
.css-26l3qy-menu{
    white-space: nowrap; width: auto !important; min-width: $width-full;
    .css-4ljt47-MenuList, .css-4ljt47-MenuList>div{white-space: nowrap;}
}
.actionbar{
    position: $position-fixed;
    width:$width-full;
    bottom:0px;
    left: 0px;
    background: $white; 
    padding: $padding5*2 $padding5*3; z-index: 99; text-align: $text-center;
    @include box-shadow(0px 0px 6px 0px $black-54);

}
.sharepopup{
    .sharedetail-scroll{max-height: 180px;
    overflow: hidden;
    overflow-y: auto;
}
    
    .share-listcar{border-bottom: solid 1px $black-12; padding:$padding5*2 $padding0;
        &:hover{background: $black-3;}
        .form-field{ margin-bottom: $margin0;
            
            
        }
        label{font-weight: $fontmedium; margin-bottom: $margin5; color: $blue-color;}
        i{vertical-align: top;}
    }
}
.modal{
    .modal-body{
        .carousel{
            .carousel__slides{
                .carousel-slide{
                    height: 410px; text-align: $text-center; display: flex;flex-direction:row;flex-flow: column;
                    background: $black-12;align-items: center;
                    justify-content: center;
                    img{max-height: 410px;}
                }
            }
            
        }
    }
}
.classifiedCheck{
    .tooltip{
        left: -72px; min-width: 160px;
    }
}
@media (max-width: 767px) {
    .modal{
        .modal-body{
            .carousel{
                .carousel__slides{
                    .carousel-slide{
                        height: auto; 
                        img{max-height: 260px; width: 100%;}
                    }
                }
                
            }
        }
    }
  }