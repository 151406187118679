.auction-filter {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0px 10px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    position: relative;
    z-index: 2;
    .filter-outer {
        display: flex;
        align-items: center;
        width: 100%;
    }
    fieldset {
        border: 0px;
        padding: 0px;
        margin: 0px;
        margin-right: 8px;
        width: 100%;
        .react_select__control {
            height: 42px;
            min-height: 42px;
            background: #ffffff;
            border: 1px solid #bbbbbb;
            border-radius: 8px;
        }
        .react_select__control,
        .react_select__control:hover {
            height: 42px;
            min-height: 42px;
            border: 1px solid #bbbbbb;
        }
    }
    .react_select__placeholder {
        font-size: 12px !important;
        font-weight: normal !important;
        color: #515151 !important;
    }

    .react_select__indicator-separator {
        display: none;
    }
    .react_select__dropdown-indicator {
        color: $heading-color !important;
        svg {
            display: none;
        }
    }

    .react_select__control {
        height: 42px;
        border-color: #bbbbbb !important;
        border-radius: 8px !important;
        min-height: 42px !important;
    }
    .react_select__control {
        border-color: #bbbbbb !important;
        box-shadow: none !important;
        border-radius: 8px !important;
        height: 42px;
        min-height: 42px !important;
    }
    .css-1pahdxg-control:hover {
        border-color: #bbbbbb !important;
        box-shadow: none;
        outline: none;
        height: 42px;
        border-color: #bbbbbb !important;
        border-radius: 8px !important;
        min-height: 42px !important;
    }
    .css-1wa3eu0-placeholder {
        font-size: 12px;
        font-weight: normal;
        color: #515151;
    }
    .css-tlfecz-indicatorContainer {
        padding: 4px !important;
    }
    .css-1gtu0rj-indicatorContainer {
        padding: 4px !important;
        color: #000 !important;

        &:hover {
            color: $heading-color !important;
        }
    }
    .multiselect-dropDown {
        .css-1r4vtzz,
        .css-48ayfv {
            height: 42px;
            border: 1px solid #bbbbbb !important;
            border-radius: 8px !important;
            background: transparent;
            width: 100%;
            box-shadow: none;
        }
        .css-1jllj6i-control {
            // width: 100%;

            box-shadow: none;
        }
        .css-6y1x9t-control {
            border-color: hsl(0, 0%, 80%) !important;
            box-shadow: none;
            &:hover {
                border-color: hsl(0, 0%, 80%) !important;
                box-shadow: none;
            }
        }
        .css-1v99tuv {
            font-size: 12px;
            position: relative;
            top: -2px;
        }
        input {
            appearance: auto;
        }
        .css-1qprcsu-option {
            font-weight: normal;
        }
    }
    // .model-sec {
    //     width: 18.3%;
    // }
    // .city-sec {
    //     width: 16.36%;
    // }
    // .kilometer-sec {
    //     width: 16.5%;
    // }
    // .year-sec {
    //     width: 13.5%;
    // }
    // .fuel-sec {
    //     width: 12.48%;
    // }

    .toggleApp {
        // width: 350px;
        margin: auto;
        margin-left: 10px;
        .d-flex {
            display: flex;
            align-items: center;
        }
    }

    .ToggleSwitch {
        background-color: rgba(34, 31, 31, 0.26);
        height: 14px;
        width: 34px;
        border-radius: 1em;
        cursor: pointer;
        &.active {
            background-color: rgba(11, 189, 221, 0.2);
        }
    }
    .ToggleSwitch .knob {
        position: relative;
        width: 20px;
        height: 20px;
        background: #f1f1f1;
        box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.237602);
        border-radius: 50%;
        left: 0em;
        transition: left 0.3s ease-out;
        top: -4px;
        &.active {
            background: #0bbddd;
            &::after {
                content: "";
                background: red;
            }
        }
    }
    .ToggleSwitch .knob.active {
        left: 15px;
    }
    .toggle-status {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
    }
    .knob ~ .toggle-status {
        color: #e02020;
    }
    .knob.active ~ .toggle-status {
        color: #000;
    }
    .toggle-switch {
        display: flex;
        align-items: center;
        margin-left: 10px;
        label {
            font-weight: normal;
            font-size: 12px;
            line-height: 18px;
            color: $heading-color;
        }
    }
    .react_select__indicators {
        display: none;
    }
}

.btn-submit-reset {
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    .btn-primary {
        background: #0bbddd;
        box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
        border-radius: 8px;
        height: 44px;
        line-height: 41px;
        font-weight: 500;
        font-size: 14px;
        color: #fff;
        cursor: pointer;
        min-width: 118px;
        border: 1px solid #0bbddd;
        outline: none;

        margin-right: 25px;
    }
    .btn-reset {
        background: transparent;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        border: 0px;
        color: #000;
        cursor: pointer;
    }
}

.auction-filter-list {
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
            display: flex;
            align-items: center;
            background: #fff;
            height: 32px;
            padding: 0px 6px 0px 17px;
            border-radius: 16px;
            margin-right: 8px;
            margin-bottom: 8px;
            span {
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: $heading-color;
                font-family: "Poppins", sans-serif;
                padding-right: 10px;
            }
            i {
                background: rgba(227, 231, 241, 0.6);
                max-height: 20px;
                max-width: 20px;
                min-height: 20px;
                min-width: 20px;
                border-radius: 100%;
                color: $heading-color;
                text-align: center;
                cursor: pointer;
            }
        }
    }
}
.react_select__dropdown-indicator {
    &:after {
        content: "\e917";
        position: absolute;
        right: 0px;
        color: rgba($heading-color, 0.7);
        font-family: dc !important;
        font-size: 24px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.multiselect-dropDown {
    .css-1gpjby2 svg {
        display: none;
    }
    &:after {
        content: "\e917";
        position: absolute;
        right: 2px;
        color: rgba(0, 0, 0, 0.7);
        font-size: 24px;
        top: 50%;
        transform: translateY(-50%);
        font-family: dc !important;
        display: none;
    }

    .css-48ayfv + div {
        box-shadow: 0 0 0 1px rgba(255, 255, 255, 1), 0 4px 11px rgba(0, 0, 0, 0.1);
    }
}


// .css-13cymwt-control {
//     height: 42px;
//     min-height: 42px;
//     border: 1px solid #bbbbbb;
//     border-radius: 8px !important;
// }

.css-1u9des2-indicatorSeparator {
    display: none;
}

.css-1xc3v61-indicatorContainer {
    color:#000 !important;
}

.css-1jqq78o-placeholder{
    font-size: 12px!important;
}