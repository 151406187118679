@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap");
.auction-main {
  h1 {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: $heading-color;
    padding: 0px;
    margin: 0px;
  }
  .auction-outer {
    .heading {
      padding: 0px 0 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .time-select {
        display: flex;
        align-items: center;
        .react_select__control {
          width: 130px;
        }
        label,
        .react_select__single-value,
        .react_select__placeholder {
          font-weight: normal;
          font-size: 13px;
          // line-height: 19px;
          color: $heading-color;
        }
        .react_select__control {
          background: transparent;
          border: 0px;
        }
        .css-2b097c-container .css-tlfecz-indicatorContainer {
          padding: 8px 0;
          color: $heading-color;
        }
        .react_select__control,
        .react_select__control:hover {
          background: transparent;
          border: 0px;
          box-shadow: none;
        }
        .css-4ljt47-MenuList {
          font-size: 13px;
          color: $heading-color;
          font-weight: normal;
        }
        .css-9gakcf-option {
          background-color: #0bbddd;
        }
        .css-26l3qy-menu {
          margin-top: 0px;
        }
        .css-1gtu0rj-indicatorContainer {
          color: $heading-color;
          padding: 8px 0;
        }
        .auction-order-filter {
          padding: 2px 0px;
          min-width: 99px;
        }
        .react_select__dropdown-indicator {
          & svg {
            display: none;
          }
        }
        .start-time-label {
          position: relative;
          top: 1px;
        }
      }
    }
  }
}

.tab-pane {
  display: none;
  &.active {
    display: block;
  }
}

.auction-list-top {
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .timer {
    background: #fff;
    padding: 0px 10px;
    font-weight: 500;
    font-size: 13px;
    line-height: normal;
    margin-left: 18px;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(36, 39, 44, 0.06);
    border-radius: 5px;
    height: 30px;
    i {
      padding-right: 10px;
    }
  }
  .heading-timer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    ul {
      width: 100%;
      display: flex;
      li {
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        color: rgba($heading-color, 0.7);
        position: relative;
        padding: 0px 11px;
        &:after {
          content: "";
          width: 3px;
          height: 3px;
          background: $heading-color;
          position: absolute;
          top: 9px;
          right: 0;
          border-radius: 100%;
        }
        &:first-child {
          padding-left: 0px;
        }
        &:last-child {
          padding-right: 0px;
          &:after {
            display: none;
          }
        }
      }
    }
  }
  .search-bx {
    position: relative;
    input {
      width: 280px;
      height: 42px;
      padding: 13px 20px 13px 50px;
      border: 1px solid #bbbbbb;
      border-radius: 8px;
      background: #fff;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $heading-color;

      &:focus-visible {
        outline: none;
      }
    }
    .searc-icn {
      position: absolute;
      left: 20px;
      font-size: 22px;
      top: 50%;
      transform: translate(0px, -50%);
    }
  }
}
