.inventory-slider {
    overflow: hidden;
    margin-top: -12px;
    position: relative;
    .bookmark-icn {
        right: calc(50% - 305px);
        top: 15px;
    }

    .inspected-detail {
        left: calc(50% - 330px);
        top: 15px;
    }

    .slick-slide img {
        width: 100%;
    }
    .slick-slide {
        width: 645px;
        padding: 0px 2px;
    }

    .slick-arrow {
        background: rgba(0, 0, 0, 0.3);
        border-radius: 5px;
        z-index: 1;
        height: 46px;
        width: 32px;
    }
    .slick-prev {
        left: calc(50% - 339px);
        &:before {
            content: "\e918";
            font-family: dc !important;
            color: #fff;
            line-height: 20px;
            opacity: 1;
            font-size: 35px;
        }
    }
    .slick-next {
        right: calc(50% - 339px);
        &:before {
            content: "\e919";
            font-family: dc !important;
            color: #fff;
            line-height: 20px;
            opacity: 1;
            font-size: 35px;
        }
    }
}

.bid-detail {
    font-family: "Poppins", sans-serif;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(36, 39, 44, 0.06);
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 10px;
    margin-top: -30px;
    position: relative;
    .heading-time-detail {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 14px;
        .heading {
            h1 {
                font-weight: 500;
                font-size: 22px;
                line-height: 30px;
                color: #000000;
                padding: 0px;
                margin: 0px;
                i {
                    position: relative;
                    top: 2.5px;
                    left: 5px;
                }
            }
        }
    }
    .timeleft {
        text-align: center;
        span {
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            color: #000000;
            display: block;
        }
        i {
            font-size: 20px;
            color: $heading-color;
        }
    }
    .bid-sec {
        justify-content: flex-start;
        .bid-left {
            margin-left: 70px;
        }
    }
}

.container-min {
    max-width: 760px;
    margin: 0 auto;
}

.numeric-btn-sec-inventory {
    &.numric-btn {
        .react-numeric-input {
            .numaric-input {
                width: 232px !important;
            }
        }
    }
}

.spec-detail-card {
    font-family: "Poppins", sans-serif;
    background: #ffffff;
    box-shadow: 0px 2px 6px rgba(36, 39, 44, 0.06);
    border-radius: 8px;
    padding: 30px 30px 15px;
    margin-bottom: 10px;

    h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 30px;
        padding: 0px 0px 5px 0;
        margin-bottom: 0px;
    }
    ul {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        li {
            border-top: 1px solid rgba($heading-color, 0.06);
            width: 33.33333333%;
            padding: 15px 10px 15px 0;
            span {
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: rgba($heading-color, 0.65);
                display: block;
            }
            label {
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                color: rgba($heading-color, 0.87);
                display: block;
            }
            &:nth-child(1) {
                border-top: 0px;
            }
            &:nth-child(2) {
                border-top: 0px;
            }
            &:nth-child(3) {
                border-top: 0px;
            }
        }
    }
}
