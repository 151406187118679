.auction-price {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-top: 20px;
}
.purchase-date {
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    color: rgba($heading-color, 0.7);
    display: block;
}
.inspected-tag {
    background: #32c5ff;
    border-radius: 4px;
    padding: 0px 7px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: #fff;
    position: absolute;
    top: 5px;
    left: 5px;
}

.purchase-bg {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    margin-top: 5px;
    .row {
        display: flex;
        flex-wrap: wrap;
    }
}

.edit-icn{position: absolute;
    top: 26px;
    right: 10px;
    font-size: 17px;}

.history-icn{
    position: absolute;
    top: 23px;
    right: -30px;
    font-size: 20px;
}

.history-model-popup{
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-content{
        padding: 20px;
        .modal-header{
            border-bottom: 0px;
        }
        table{
            tbody{
                tr{
                    th{
                        background-color: #d1d1d1;
                        &:first-child{
                            border-radius: 8px 0 0 0;
                        }
                        &:last-child{
                            border-radius: 0px 8px 0 0;
                        }
                    }
                    
                }
            }
        }
    }
}


