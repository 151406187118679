@import "caret";
.dropdown{ position: $position-relative; display: $display-inline-block;
    a{
        color: $black-87; display: block;width: auto; padding: 20px; @include transition(all 0.3s ease-in-out);
        .caret{
            @include caret; margin-left: $caret-spacing; }                   
    }    
    ul.dropdown-menu{
        display: $display-none; position: $position-absolute; min-width: 130px;  width:auto; z-index: 999; background:$white; @include border-radius(0 0 3px 3px); @include transition(all 0.3s ease-in-out);@include box-shadow(0 4px 8px 0px rgba(0, 0, 0, 0.3));
        li{
            float: inherit; position: $position-relative;width:100%; text-align: $text-left; border-bottom: solid 1px $black-12;
                
            a{padding:10px 20px; color: $black-54; white-space: nowrap; cursor: pointer;
                &:hover{
                    color: $primary-color; background: $black-3;
                } 
            }
            &:last-child{border-bottom:none;}
        }
    }
    &:hover{
        ul.dropdown-menu{display: $display-block;}
        
    }
    &:hover{
        >a{color: $primary-color; } 
}
    
}
    
.dropdown-menu{display:none;}