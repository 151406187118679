
// Fix for jumping arrows
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 210px;
}

.carousel {
  position: relative;
}

// Carousel slides
.carousel__slide {
  margin-right: auto;
  margin-left: auto;
  display: none;
  // max-width: 900px;
  list-style-type: none;
  @include transition(transform .6s ease-in-out);
  // animation: myfirst 0.4s 1;
  // animation-direction: normal;


  @media (max-width: 991px) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @media (max-width: 767px) {
    padding-right: 0px;
    padding-left: 0px;
  }

  &--active {
    display: block;
    
  }
}

// Content of slides
.carousel-slide__content {
  margin-bottom: 19px;
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
  font-size: 16px;

 
}
.carousel-slideimg{
  max-width: $width-full;
}

.carousel-slide__author,
.carousel-slide__source {
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;

 
}

.carousel-slide__source {
  font-style: italic;
  color: #888;
}

// Carousel arrows
.carousel__arrow {
  position: absolute;
  top: 50%;
  display: block;
  color: #111;
  cursor: pointer;
  margin-top:-16px;
  opacity: .6;
  transform: translateY(-50%);
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);
  background: rgba($black , 0.54); @include border-radius(50%);
  width:32px;height: 32px; text-align: $text-center; line-height: 32px;
  z-index: 9;

  &:focus {
    outline: 0;
  }

  &:hover {
    opacity: .9;
  }

  &--left {
    left: 15px;
  }

  &--right {
    right: 15px;
  }
}
.crarrow{
  border: solid $white;
  border-width: 0 2px 2px 0;
  display: $display-inline-block;
  padding: 4px;
}
.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

// Carousel indicators
.carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;

  li {
    &:nth-of-type(n + 2) {
      margin-left: 9px;
    }
  }
}

.carousel__indicator {
  display: block;
  width: 24px;
  height: 3px;
  background-color: #111;
  cursor: pointer;
  opacity: .15;
  transition: opacity .15s cubic-bezier(.4, 0, 1, 1);

  &:hover {
    opacity: .5;
  }

  &--active {
    &,
    &:hover {
      opacity: .75;
    }
  }
}
.thumbbx{
    display: flex;
    overflow: hidden;
    overflow-x: auto;
    margin-top: $margin5*2;
}
.carouse-thumb{
    display: flex;
    flex-direction: row;
    justify-content: center;
  li{ margin-right: 4px;background: $black-3; cursor: $cursor-pointer;@include transition(all 0.3s);
    &:hover{@include transition(all 0.3s);
      .thumb{opacity: 1;border: solid 2px $black-12; }

    }
.thumb{display: block; opacity: 0.6; @include transition(all 0.3s); 
    width: 100px;
    height: auto;
    border: solid 2px transparent;
    &.active{border: solid 2px $primary-color; opacity: 1;}
img{max-width: 100%; width:100%; max-height: 54px;}
}
}
}

@keyframes myfirst {
  0%   {transform:translateX(0%)}
  100% {transform:translateX(100%)}
}