@import "theme/include";
@import "theme/loginpage";
@import "theme/header";
@import "theme/footer";
@import "theme/addstock";
@import "theme/managestock";
@import "theme/dashboard";
@import "theme/modal";
@import "theme/carousal";
@import "theme/manageenquiry";
@import "theme/myaccount";
@import "../../view/login/assets/scss/register";
body{background: $bodycolor;font-size: $font14; color: $black-87;font-family: $font-family;}
