@import "LoginForm";
#homePage {
    position: inherit;
    padding: 0;
    margin: 0;
    font-family: $font-family;
        .leftBlueBg {
        position: $position-relative;
        color: $white;
        .lgHeading{font-size: 36px; color: $white;text-align: $text-center; font-weight: 100; line-height: 1.1234em; margin-bottom: $margin0;}
        p{ margin-bottom:10px;}
        
    }
    .blueBg {padding: 15px 30px; position: $position-relative;
    right: 0;
    top: 0;
    background: $headerbg url(https://dealercentral.gaadi.com/images/bg_with_road.jpeg)  no-repeat bottom right ;
    width: $width-full;
    height: $height-full;
    padding: 15px;
    text-align: $text-center;
    overflow: hidden;
    .animationImage{position: $position-absolute; bottom: 10px; left: 0; z-index: 999; width: 100px; transition-timing-function: ease-out; animation:caranimate 4s infinite;-webkit-animation:caranimate 4s infinite; animation-direction: normal; }
    
    }
    .logoSec{text-align: $text-center;
    img{max-height: 32px;
    }
}
    .rightWhiteBg{ 
        width: $width-full;
        position: $position-relative;
        float: $float-left;
        height: $height-full;
        background:$white;
        overflow-y:auto;
       
            .navbar{background: transparent; padding: 10px;
                .navbar-nav{width:$width-full;
                    li{width:33.3333%;font-size:$font14;text-align: $text-center; position: $position-relative;  float: $float-left; padding: 8px 10px; color:$black-54; border-bottom:solid 2px transparent;
                        &.active{border-bottom:solid 2px $primary-color; color:$primary-color;}
                    }

                }
            }
            footer {
                padding: 50px 0; background: transparent;
            
            .footer {
                font-size: $font14;
                color:$black-37;
                position: initial;
                padding-right: $padding5*3;
                text-align: $text-right;
            }
        }
        
    }
    
}
@media not all, (-webkit-transform-3d){
.carousel-inner>.item {
    -webkit-transition: -webkit-transform .6s ease-in-out;
    -o-transition: -o-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000;
    perspective: 1000;
}
}
@-webkit-keyframes caranimate {
    0%   {left: -500px; }
    100% { left: 100%;}
  }

@keyframes caranimate {
    0%   {left: -500px; }
    100% { left: 100%;}
  }
.playStore {
    margin-bottom: 30px;
a{ display: $display-inline-block;}
img{max-width: 130px;}
}
.homeMobile, .img-mobile-bg1 {
    display: none;
}

.videoSec{
    position: $position-absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 48px;
    width: 172px;
    /* margin-left: 223px; */
}
.slide-wrapper {
    padding: $padding5*3;
    width: $width-full;
}

.carousel{
        .carousel-inner {
            position: $position-relative;
            overflow: $overflow-hidden;
            width: $width-full;
            .item{@include transition(.6s ease-in-out left);-webkit-backface-visibility: hidden;
                backface-visibility: hidden;
                -webkit-perspective: 1000;}
            > .item.next, >.item.active.right {@include translate3d(0, 100%, 0); top: 0;}
            > .item.prev , > .item.active.left{@include translate3d(0, -100%, 0); top: 0;}
            > .item.next.left , > .item.prev.right , >.item.active{@include translate3d(0, 0, 0); left: 0;}
            .slide-wrapper .sm{width: $width-full;}
    }
}
.carousel-indicators{display: none;}


@media(min-width: $breakpoint-tab1) {
    #homePage {
        position: $position-absolute;
        left: 0;
        top: 0;
        width: $width-full;
        height: $height-full;
        overflow: $overflow-hidden;
        padding: 0;
        margin: 0;
        font-family: $font-family;

        .logoSec{ 
        text-align: $text-left;}
        .rightWhiteBg{ width: $width-half; 
            
            .navbar{
                .navbar-nav{width:$width-auto; float: $float-right !important; padding-right: 30px;
                    li{width:$width-auto; margin-right: 10px; float: $float-left; white-space: nowrap; cursor: $cursor-pointer;}
                    }
    
                }
            
            }
            .leftBlueBg {
                width: $width-half;height: $height-full; float: $float-left;
                .lgHeading{font-size: 48px; margin-top: 30px; }
                p{ margin-bottom: 15px;}
            }
    }
    
    .playStore {
       img{max-width: 100%;}
    }
    .homeMobile {
        position: $position-absolute;
        bottom: 13px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        display: $display-block;
    }
    .blueBg {
        .animationImage{display: $display-block;
        img{max-width: 70px;}}
        }
    .slide-wrapper {
        padding: 40px 0px 0px 80px;
        max-width: 550px;
    }
    .slide-wrapper .sm{width: 360px;}
    .carousel-indicators{position: fixed; display: block;text-align: center;
        top: 15%;
        right: 10px;
        bottom: 0;
        margin: auto;
        height: 60px;
        right: 10px;
        left: auto;
        width: auto;
    li{display: $display-block; width:15px; height:15px;text-indent: -999px;border-radius: 10px;
        cursor: pointer;
        margin-bottom: 5px;
        border: 3px solid #fff;
        background:rgba($black, 0.12); @include transition(all 0.3s ease-in-out);
    &.active{border: 3px solid rgba($white, 0.77);background: $primary-color;
        &:hover,&:focus,&:active{border: 3px solid rgba($white, 0.77) !important;background: $primary-color !important;}
    }
    &:hover,&:focus,&:active{border: 3px solid rgba($primary-color, 0.12);background: $white;}
}
}
    




}